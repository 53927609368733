import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const form = false;

const ThankYou = () => (
  <Layout form={form}>
    <SEO title="Thank you" ogUrl="/thank-you" />

    <section className="wrapper style2 alt">
      <div className="inner">
        <header className="major">
          <h2>Thank you</h2>
        </header>

        <p>Thank you for reaching out. I'll get back to you asap!</p>
      </div>
    </section>
  </Layout>
);

export default ThankYou;
